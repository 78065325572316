import { useTranslation } from "react-i18next";
import { Helmet, HelmetProvider } from "react-helmet-async";

const HelmetStrap = () => {
  const { i18n } = useTranslation();

  let lang = "en";
  if (i18n.language) {
    lang = i18n.language;
  }

  return (
    <HelmetProvider>
      <Helmet>
        <html lang={lang} />
      </Helmet>
    </HelmetProvider>
  );
};

export default HelmetStrap;
