const ADMIN_BASE = "/ta";
const MAGIC_LINK_BASE = "/ml";
const TAKER_BASE = "/testtaker";
const SANDBOX_BASE = "/sb";
const EVALI_CONSTANTS = {
  // routes
  AUTH_PAGE: "/",
  NS_SIGN_IN: "/ns-sign-in",
  TOKEN: "/token",
  SIGNOUT_PAGE: "/signout",
  MFA_INFO: "/mfa-info",
  // admin
  SIGNUP_PAGE: "/signup",
  JOIN_PAGE: "/join",
  LOBBY: "/lobby",
  TOTP_AUTH: "/totp-auth",
  ADMIN_SETUP: "/setup",
  ADMIN_START: `${ADMIN_BASE}`,
  ADMIN_USER: `${ADMIN_BASE}/user`,
  ADMIN_TESTTAKERS: `${ADMIN_BASE}/testtaker`,
  ADMIN_SETTING: `${ADMIN_BASE}/setting`,
  ADMIN_INTEGRATION: `${ADMIN_BASE}/integration`,
  ADMIN_ASSESS: `${ADMIN_BASE}/assess`,
  ADMIN_READ_BACK_RESULTS: `${ADMIN_BASE}/read-back-results`,
  ADMIN_MATCHPROFILE: `${ADMIN_BASE}/matchprofile`,
  ADMIN_TEAM: `${ADMIN_BASE}/teamprofile`,
  ADMIN_DEV: `${ADMIN_BASE}/grow`,
  ADMIN_NOTIFICATIONS: `${ADMIN_BASE}/notifications`,
  ADMIN_SINGLE_RESULT: `/single-result`,
  // admin user
  ADMIN_USER_PREFERENCES: `/user-preferences`,
  // taker
  TAKER_PAGE: `${TAKER_BASE}`,
  TAKER_PROFILE: `${TAKER_BASE}/profile`,
  TAKER_PREFERENCES: `${TAKER_BASE}/preferences`,
  TAKER_SIGNUP: `${TAKER_BASE}/signup`,
  // taker, not signed in
  TAKER_WITH_TOKEN: `/with-token`,
  // actual tests
  PERSONALITY_BASE: "/ept",
  ADAPTIVE_REASONING_BASE: "/erg",
  REASONING_BASE: "/ert",
  REASONING_INIT: "/reasoning-init",
  TEAM_INSIGHT_BASE: "/eti",
  // sandbox
  SANDBOX_START: `${SANDBOX_BASE}`,
  SANDBOX_REASONING: `${SANDBOX_BASE}/ert`,
  // misc
  ERT_IMG_SIZE: "140",
  POLLING_INTERVAL: 60, // in seconds
  MAGIC_LINK_START: MAGIC_LINK_BASE,
  MAGIC_LINK_RESULT: `${MAGIC_LINK_BASE}/result`,
  MAGIC_LINK_FALLBACK: `${MAGIC_LINK_BASE}/fallback`,
};
export default EVALI_CONSTANTS;

// RouteControl
export const AUTH_ALLOWLIST = [
  EVALI_CONSTANTS.AUTH_PAGE,
  EVALI_CONSTANTS.PERSONALITY_BASE,
  EVALI_CONSTANTS.REASONING_BASE,
  EVALI_CONSTANTS.ADAPTIVE_REASONING_BASE,
  EVALI_CONSTANTS.TEAM_INSIGHT_BASE,
  EVALI_CONSTANTS.TAKER_WITH_TOKEN,
  EVALI_CONSTANTS.SIGNUP_PAGE,
  EVALI_CONSTANTS.JOIN_PAGE,
];
