import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import Cookies from "js-cookie";

const getBestMatch = (codes, available_lngs) => {
  if (!codes) return null;
  let found;
  codes.forEach((code) => {
    if (found) return;
    const code_base_form = code.split("-")[0];
    if (available_lngs.includes(code_base_form)) {
      found = code_base_form;
    }
  });
  return found;
};

export function evali_get_preferred_lang() {
  const available_lngs = ["en", "sv"];
  const query_args = new URLSearchParams(window.location.search);
  let lang = "en";

  // this snippet is taken from the i18next project.
  let codes_from_navigator = [];
  if (typeof navigator !== "undefined") {
    if (navigator.languages) {
      // chrome only; not an array, so can't use .push.apply instead of iterating
      for (let i = 0; i < navigator.languages.length; i++) {
        codes_from_navigator.push(navigator.languages[i]);
      }
    }
    if (navigator.userLanguage) {
      codes_from_navigator.push(navigator.userLanguage);
    }
    if (navigator.language) {
      codes_from_navigator.push(navigator.language);
    }
  }

  const best_navigator_match = getBestMatch(
    codes_from_navigator,
    available_lngs
  );

  if (best_navigator_match) {
    lang = best_navigator_match;
  }

  if (
    query_args.get("lang") !== null &&
    available_lngs.includes(query_args.get("lang"))
  ) {
    lang = query_args.get("lang");
    Cookies.set("evali-lang", lang);
  }
  const cookie_lang_value = Cookies.get("evali-lang");
  if (cookie_lang_value && available_lngs.includes(cookie_lang_value)) {
    lang = cookie_lang_value;
  }
  return lang;
}

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    lng: evali_get_preferred_lang(),
    fallbackLng: "en",
    backend: {
      loadPath: "/lang/{{lng}}/{{ns}}.json",
    },
    keySeparator: false,
    nsSeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
