import Cookies from "js-cookie";
import { Fragment } from "react";
import { Redirect, useLocation } from "react-router-dom";
import Spinner from "./components/spinner";

import EVALI_CONSTANTS from "./constants";
import useAuth from "./hooks/auth";

const hasRequiredMfa = (user_data) => {
  return (
    user_data?.mfa?.required ||
    user_data.organizations?.find(
      (org) => org.id === parseInt(Cookies.get("evali-org"))
    )?.mfa?.required
  );
};

const ProtectedRoute = ({ organization, children }) => {
  const { user, user_data, isMfaVerified, is_loading } = useAuth();
  const location = useLocation();
  if (is_loading) return <Spinner height="12rem" />;

  let url_params = new URLSearchParams(location.search);
  const to_param = url_params.get("to");
  url_params.delete("to");

  if (user === null) {
    // this user is NOT signed in
    if (to_param !== null) {
      return (
        <Redirect to={{ pathname: to_param, search: url_params.toString() }} />
      );
    } else {
      if (location.pathname !== "/") {
        url_params.append("to", location.pathname);
      }
      return (
        <Redirect
          to={{
            pathname: EVALI_CONSTANTS.AUTH_PAGE,
            search: url_params.toString(),
          }}
        />
      );
    }
  } else if (hasRequiredMfa(user_data) && !isMfaVerified()) {
    if (to_param !== null) {
      return (
        <Redirect to={{ pathname: to_param, search: url_params.toString() }} />
      );
    } else {
      if (location.pathname !== "/") {
        url_params.append("to", location.pathname);
      }
      return (
        <Redirect
          to={{
            pathname: EVALI_CONSTANTS.TOTP_AUTH,
            search: url_params.toString(),
          }}
        />
      );
    }
  } else if (organization && !user_data?.organizations.length) {
    // this is not an organization user
    return <Redirect to={{ pathname: EVALI_CONSTANTS.TAKER_PAGE }} />;
  } else if (organization && !user_data?.role) {
    // User is missing evali-org cookie. Let's set it
    if (to_param !== null) {
      return (
        <Redirect to={{ pathname: to_param, search: url_params.toString() }} />
      );
    } else {
      if (location.pathname !== "/") {
        url_params.append("to", location.pathname);
      }
      return (
        <Redirect
          to={{
            pathname: EVALI_CONSTANTS.LOBBY,
            search: url_params.toString(),
          }}
        />
      );
    }
  }

  return <Fragment>{children}</Fragment>;
};

export default ProtectedRoute;
