import { useEffect } from "react";
import { getAuth, getIdToken } from "firebase/auth";

import i18n from "../i18n";

/* can be used as a hook as well as standalone */

const REJECT_STATUS_CODES = [400, 401];
const CONTENTLESS_STATUS_CODES = [204];

const createFetchError = (message, response_body) => {
  const err = new Error(message);
  err.name = "FetchError";
  err.response_body = response_body;
  return err;
};

const default_headers = {
  "content-type": "application/json",
  // prettier-ignore
  "accept": "application/json",
};

export const request = async (url, options = {}, token_header = true) => {
  let response_body;

  if (token_header) {
    const auth = getAuth();
    if (!auth.currentUser) {
      return Promise.reject("Not signed in");
    }
    const id_token = await getIdToken(auth.currentUser);
    options = {
      credentials: "include",
      mode: "cors",
      ...options,
      headers: {
        ...default_headers,
        "x-evali-auth": `Bearer ${id_token}`,
        "x-evali-lang": i18n.language,
      },
    };
  } else {
    options = {
      ...options,
      headers: { ...default_headers, "x-evali-signup": true },
    };
  }

  let compiled_options = { ...options };
  if (options.body && typeof options.body !== "string") {
    compiled_options = {
      ...compiled_options,
      body: JSON.stringify(options.body),
    };
  }

  const response = await fetch(url, compiled_options);

  // we have an error :(
  if (!response.ok) {
    try {
      response_body = await response.json();
    } catch (e) {
      response_body = "";
    }
    if (REJECT_STATUS_CODES.indexOf(response.status) !== -1) {
      return Promise.reject(response_body);
    } else if (response.status === 404) {
      // assuming the 404 returns JSON
      response_body = { ...response_body, status: 404 };
    } else {
      // FIXME throw or return something structured?
      return Promise.reject(
        createFetchError(response.statusText, response_body)
      );
    }
  } else {
    // add this global variable to disable eternal polling
    window.evali_last_request_timestamp = Math.round(
      new Date().getTime() / 1000
    );
    // everything is golden
    if (CONTENTLESS_STATUS_CODES.indexOf(response.status) !== -1) {
      response_body = {};
    } else {
      response_body = await response.json();
    }
  }
  return response_body;
};

const useRequest = () => {
  const abortcontroller = new AbortController();

  const requestFn = (url, options) => {
    options = { signal: abortcontroller.signal, ...options };
    return request(url, options).catch((err) => {
      if (err.name === "AbortError") {
        return { message: "Request aborted" };
      } else {
        throw err;
      }
    });
  };

  useEffect(() => {
    return () => {
      abortcontroller.abort();
    };
  }, []);

  return requestFn;
};

export default useRequest;
