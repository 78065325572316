export function addAnswerToStreak(correct) {
  const session_streak = getAnswerStreak();
  let new_streak = [];
  if (session_streak === null) {
    new_streak.push(correct ? 1 : 0);
  } else {
    new_streak = [...session_streak];
    new_streak.push(correct ? 1 : 0);
    new_streak = new_streak.slice(-10);
  }
  sessionStorage.setItem("evali-sandbox-session", JSON.stringify(new_streak));
}

export function getAnswerStreak() {
  return JSON.parse(sessionStorage.getItem("evali-sandbox-session"));
}

export function testIfSessionStorageAvailable() {
  try {
    sessionStorage.setItem("testIfSessionStorageAvailable", true);
    const result = sessionStorage.getItem("testIfSessionStorageAvailable");
    sessionStorage.removeItem("testIfSessionStorageAvailable");
    return result;
  } catch {
    return false;
  }
}
