import { lazy, Suspense } from "react";
import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { lazyRetry } from "./helpers";

if (["production", "staging"].includes(SENTRY_ENV)) {
  Sentry.init({
    dsn: "https://3554fc9ddf5242ae9c31a955b057bd41@o451249.ingest.sentry.io/5436925",
    traceSampleRate: 0,
    environment: SENTRY_ENV,
  });
}

import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";

// polyfill
import "whatwg-fetch";
// babel
import "regenerator-runtime/runtime";

import "./i18n";
import EVALI_CONSTANTS from "./constants";
import "../scss/assemble.scss";
import { AuthProvider } from "./hooks/auth";
import HelmetStrap from "./components/helmet-strap";

initializeApp(EVALI_FIREBASE_CONFIG);

if (EVALI_ENV === "dev") {
  const auth = getAuth();
  connectAuthEmulator(auth, "http://localhost:9099", { disableWarnings: true });
}

const AuthPageLoader = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: 'auth' */ "./pages/auth"))
);
const LobbyPageLoader = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: 'lobby' */ "./pages/lobby"))
);
const EvaliTestLoader = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: 'evali-test' */ "./evali-test"))
);
const EvaliLoader = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: 'evali-admin' */ "./evali"))
);
const EvaliSandboxLoader = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'evali-sandbox' */ "./evali-sandbox")
  )
);
const NsSignInLoader = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'ns-sign-in' */ "./components/ns-sign-in")
  )
);
const TokenLoader = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: 'token' */ "./pages/token"))
);
const MagicLinkIndexLoader = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'magic-link-index' */ "./magic-link-index")
  )
);
import Spinner from "./components/spinner";
import ProtectedRoute from "./protected-route";

ReactDOM.render(
  <Suspense fallback={<Spinner height="20rem" />}>
    <HelmetStrap />
    <Router>
      <AuthProvider>
        <Switch>
          <Route exact path={EVALI_CONSTANTS.AUTH_PAGE}>
            <Suspense fallback={<Spinner height="12rem" />}>
              <AuthPageLoader />
            </Suspense>
          </Route>
          <Route exact path={EVALI_CONSTANTS.LOBBY}>
            <ProtectedRoute>
              <Suspense fallback={<Spinner height="12rem" />}>
                <LobbyPageLoader />
              </Suspense>
            </ProtectedRoute>
          </Route>
          <Route
            path={[
              EVALI_CONSTANTS.SANDBOX_START,
              EVALI_CONSTANTS.SANDBOX_REASONING,
            ]}
          >
            <Suspense fallback={<Spinner height="12rem" />}>
              <EvaliSandboxLoader />
            </Suspense>
          </Route>
          <Route
            path={[
              EVALI_CONSTANTS.PERSONALITY_BASE,
              EVALI_CONSTANTS.REASONING_BASE,
              EVALI_CONSTANTS.ADAPTIVE_REASONING_BASE,
              EVALI_CONSTANTS.TEAM_INSIGHT_BASE,
              EVALI_CONSTANTS.TAKER_WITH_TOKEN,
              EVALI_CONSTANTS.TAKER_PAGE,
              EVALI_CONSTANTS.TAKER_SIGNUP,
              EVALI_CONSTANTS.REASONING_INIT,
            ]}
          >
            <Suspense fallback={<Spinner height="12rem" />}>
              <EvaliTestLoader />
            </Suspense>
          </Route>
          <Route path={EVALI_CONSTANTS.NS_SIGN_IN}>
            <NsSignInLoader />
          </Route>
          <Route path={`${EVALI_CONSTANTS.TOKEN}/:token`}>
            <Suspense fallback={<Spinner height="12rem" />}>
              <TokenLoader />
            </Suspense>
          </Route>
          <Route path={EVALI_CONSTANTS.MAGIC_LINK_START}>
            <Suspense fallback={<Spinner height="12rem" />}>
              <MagicLinkIndexLoader />
            </Suspense>
          </Route>
          <Route>
            <Suspense fallback={<Spinner height="12rem" />}>
              <EvaliLoader />
            </Suspense>
          </Route>
        </Switch>
      </AuthProvider>
    </Router>
  </Suspense>,
  document.getElementById("root")
);

if (window.location.host.startsWith("app-stage")) {
  const stage_warn = document.createElement("div");
  stage_warn.setAttribute("id", "stage-warn");
  stage_warn.textContent = "⚠ STAGING ⚠";

  const stage_warn_style = document.createElement("style");
  stage_warn_style.textContent = [
    "#stage-warn {",
    "position: fixed;",
    "top: 2rem;",
    "left: -4rem;",
    "height: 2rem;",
    "width: 300px;",
    "background-color: #ff3333;",
    "transform: rotate(-30deg);",
    "color: white;",
    "font-weight: 500;",
    "display: flex;",
    "align-items: center;",
    "justify-content: center;",
    "box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);",
    "z-index: 2;",
    "}",
  ].join("");

  document.body.appendChild(stage_warn);
  document.body.appendChild(stage_warn_style);
}
