const Spinner = ({ height, message }) => {
  return (
    <div className="evali-spinner-wrapper" style={{ height: `${height}` }}>
      <div className="evali-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className="evali-spinner__message">{message}</div>
    </div>
  );
};

export default Spinner;
