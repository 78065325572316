import { isNull, isUndefined } from "lodash-es";
import { marked } from "marked";
import queryString from "query-string";

import { evali_get_preferred_lang } from "./i18n";

export const renderParagraphs = (p_list) => {
  if (isUndefined(p_list)) {
    return null;
  }
  const content = marked.parse(p_list.join("\n\n"));
  return (
    <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
  );
};

export const renderMarkdown = (content) => {
  if (isNull(content) || isUndefined(content)) {
    return null;
  }
  const markdown_content = marked.parse(content);
  return (
    <div
      className="content"
      dangerouslySetInnerHTML={{ __html: markdown_content }}
    />
  );
};

export const copyToClipboard = (value) => {
  const el = document.createElement("textarea");
  el.value = value;
  document.body.appendChild(el);
  if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
    el.contentEditable = true;
    el.readOnly = true;

    let range = document.createRange();
    range.selectNodeContents(el);

    let selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    el.setSelectionRange(0, 999999);
  } else {
    el.select();
  }
  document.execCommand("copy");
  document.body.removeChild(el);
};

export const getQueryParams = () => {
  return queryString.parse(window.location.search);
};

export const randomStr = () => {
  return Math.random().toString(36).substring(2, 12);
};

export const isIE = () => {
  const ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
  const msie = ua.indexOf("MSIE "); // IE 10 or older
  const trident = ua.indexOf("Trident/"); //IE 11

  return msie > 0 || trident > 0;
};

export class NetworkError extends Error {
  constructor(message, json) {
    super(message);
    this.name = "NetworkError";
    this.json = json;
  }
}

export const slugify = (str) => {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaeeeeiiiioooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
};

export const hasAccess = (user_roles, allowed_roles) => {
  if (isUndefined(user_roles)) {
    return false;
  }
  if (user_roles.includes("nightstalker")) {
    return true;
  }
  let allowed = false;
  allowed_roles.forEach((role) => {
    if (user_roles.includes(role)) {
      allowed = true;
    }
  });
  return allowed;
};

export const getReasoningVersion = () => {
  return "/reasoning-init";
};

export const getPersonalityVersion = () => {
  if (evali_get_preferred_lang() === "sv") {
    return EVALI_DEFAULT_PERSONALITY_SV;
  } else {
    return EVALI_DEFAULT_PERSONALITY;
  }
};

export const getScoreName = (item, score) => {
  let score_name = null;

  const low_limit = 4;
  const high_limit = 7;

  if (score < low_limit) {
    score_name = item.low_score_name;
  } else if (score >= low_limit && score < high_limit) {
    score_name = item.avg_score_name;
  } else if (score >= high_limit) {
    score_name = item.high_score_name;
  }

  return score_name;
};

const FEATURE_FLAG_STORAGE_KEY = "evali-feature-flags";

export const getFeatureFlags = () => {
  let flag_store = {};
  try {
    flag_store = localStorage.getItem(FEATURE_FLAG_STORAGE_KEY)
      ? JSON.parse(localStorage.getItem(FEATURE_FLAG_STORAGE_KEY))
      : {};
  } catch (e) {
    flag_store = {};
  }
  return flag_store;
};

export const hasFeature = (key) => {
  const flag_store = getFeatureFlags();
  if (key in flag_store) {
    return flag_store[key];
  }
  return false;
};

export const setFeatureFlag = (key, is_on) => {
  let flag_store = getFeatureFlags();
  const store_copy = {
    ...flag_store,
    [key]: is_on,
  };
  localStorage.setItem(FEATURE_FLAG_STORAGE_KEY, JSON.stringify(store_copy));
  return;
};

export const lazyRetry = (fn, retries = 5, interval = 500, retry_count = 0) => {
  // adapted from - https://goenning.net/2018/11/16/how-to-retry-dynamic-import-with-react-lazy/
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retries === 1) {
            reject(error);
            return;
          }

          lazyRetry(fn, retries - 1, interval, retry_count + 1).then(
            resolve,
            reject
          );
        }, interval * retry_count);
      });
  });
};

export const randomFromTime = () => {
  const now = new Date();
  return `${now.getMinutes()}-${now.getSeconds()}-${now.getMilliseconds()}`;
};

export const hasSearchParams = () => {
  const search_params = new URLSearchParams(window.location.search);
  return [...search_params].length > 0;
};

export const normalizeReasoningScore = (reasoning_result) => {
  let correct_percent = 0;
  let sten_score = 0;
  let percentile_range = [0, 0];
  let percentile = 0;
  let correct_count = 0;
  let total_count = 0;

  if (reasoning_result) {
    if (Object.prototype.hasOwnProperty.call(reasoning_result, "sten_score")) {
      // newer adaptive reasoning results
      ({
        correct_count,
        total_count,
        sten_score,
        percentile,
        percentile_range,
      } = reasoning_result);
      correct_percent =
        reasoning_result.correct_count / reasoning_result.total_count;
    } else {
      // older linear reasoning results
      [correct_count, total_count, , percentile, sten_score, percentile_range] =
        reasoning_result;
      correct_percent = correct_count / total_count;
    }
  }

  const correct_percent_show = Math.round(correct_percent * 100);

  return {
    correct_percent_show,
    sten_score,
    percentile_range,
    percentile,
    correct_count,
    total_count,
  };
};
